import React from 'react';
import { Link } from 'gatsby';
import { Head, SiteFooter } from '~/components';
import * as styles from '~/styles/not_found.module.scss';
import { IconLogo } from '~/icons';

const NotFoundPage = () => {
  return (
    <div className={styles.container}>
      <Head title="404: Not Found" />

      <header className="u-header">
        <h1 className="header-col header-logo">
          <Link to="/">
            <IconLogo width={153} height={40} />
          </Link>
        </h1>
      </header>

      <main className={styles.main}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.number}>404</div>
            <h2 className={styles.title}>Page Not Found</h2>
            <div className={styles.description}>
              お探しのページは見つかりませんでした<br />
              URLが存在しないか間違っている可能性がございます。
              </div>
          </div>
        </div>
      </main>
      <SiteFooter />

    </div>
  )
}

export default NotFoundPage;
